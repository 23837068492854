/*Header*/

.header {
	padding: 30px 0 0 0;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 90;
	&.opened {
		overflow: auto;
		height: 100%;
	}
	.logo {
		float: left;
		width: 248px;
		font-size: 0;
		img {
			max-width: 100%;
		}
	}
	.contacts-cols {
		float: right;
		font-size: 0;
		.contacts-col {
			margin-left: 40px;
			display: inline-block;
			vertical-align: top;
			width: 160px;
			text-align: right;
			&:first-child {
				margin-left: 0;
			}
			.tel {
				margin: 0 0 5px 0;
				display: block;
				font-weight: bold;
				font-size: 18px;
				color: #303030;
				text-decoration: none;
			}
			.mail {
				margin: 0 0 5px 0;
				display: inline-block;
				font-size: 14px;
				line-height: 20px;
				color: #303030;
				text-decoration: none;
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);
				@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
				&:hover {
					border-bottom-color: rgba(0, 0, 0, 1);
				}
			}
			.call-btn {
				display: inline-block;
				vertical-align: top;
				font-size: 14px;
				line-height: 20px;
				color: #F5A900;
				text-decoration: none;
				border-bottom: 1px solid rgba(249, 185, 43, 0.2);
				@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
				&:hover {
					border-bottom-color: rgba(249, 185, 43, 1);
				}
			}
		}
	}
	.slogan-items {
		margin-top: 20px;
		margin-right: 90px;
		float: right;
		.slogan-item {
			padding-left: 28px;
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: 150px;
			font-size: 14px;
			line-height: 16px;
			color: #716555;
			&.item-1 {
				&:before {
					background: url(../images/car.svg) no-repeat;
					background-size: contain;
				}
			}
			&.item-2 {
				&:before {
					background: url(../images/dollar.svg) no-repeat;
					background-size: contain;
				}
			}
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 24px;
				height: 24px;
			}
		}
	}
	.main-menu-fw {
		margin-top: 20px;
		height: 50px;
		line-height: 50px;
		background: $base_hover_color;
		.main-menu {
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				font-size: 0;
				li {
					margin-right: 30px;
					display: inline-block;
					vertical-align: top;
					a {
						position: relative;
						display: flex;
						align-items: center;
						height: 50px;
						font-size: 18px;
						color: #232323;
						text-decoration: none;
						span {
							position: relative;
							display: inline-block;
							vertical-align: top;
							line-height: 26px;
							&:before {
								content: '';
								position: absolute;
								left: 0;
								bottom: 0;
								width: 0%;
								height: 2px;
								background: #232323;
								@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
							}
						}
						&:hover {
							span {
								&:before {
									width: 100%;
								}
							}
						}
					}
					&.active {
						a {
							span {
								&:before {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
	.menu-btn {
		margin-top: 9px;
		position: relative;
		float: right;
		display: none;
		width: 34px;
		height: 34px;
		font-size: 0;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: url(../images/menu_ic.svg) no-repeat center center;
			background-size: contain;
		}
	}
}

.menu-popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #000;
	opacity: 0.6;
	z-index: 98;
}

.menu-popup {
	padding: 80px 30px 60px 30px;
	position: fixed;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	top: 0;
	right: -280px;
	width: 280px;
	height: 100%;
	background: $extra_background;
	@include transition(all 0.4s ease 0s);
	z-index: 99;
	&.opened {
		right: 0;
	}
	.contacts {
		margin-top: 30px;
		margin-bottom: 30px;
		&:first-child {
			margin-top: 0;
		}
		.tel {
			margin: 0 0 5px 0;
			display: block;
			font-weight: bold;
			font-size: 18px;
			color: #303030;
			text-decoration: none;
		}
		.mail {
			margin: 0 0 5px 0;
			display: inline-block;
			font-size: 14px;
			line-height: 20px;
			color: #303030;
			text-decoration: none;
			border-bottom: 1px solid #303030;
			@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
		}
		.call-btn {
			display: inline-block;
			vertical-align: top;
			font-size: 14px;
			line-height: 20px;
			color: #303030;
			text-decoration: none;
			border-bottom: 1px solid #303030;
			@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
		}
	}
	.slogan-items {
		margin-top: 30px;
		margin-bottom: 30px;
		.slogan-item {
			margin-bottom: 15px;
			padding-left: 30px;
			position: relative;
			font-size: 14px;
			line-height: 16px;
			color: #232323;
			&:last-child {
				margin-bottom: 0;
			}
			&.item-1 {
				&:before {
					background: url(../images/car.svg) no-repeat;
					background-size: contain;
					filter: brightness(0%);
				}
			}
			&.item-2 {
				&:before {
					background: url(../images/dollar.svg) no-repeat;
					background-size: contain;
					filter: brightness(0%);
				}
			}
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 24px;
				height: 24px;
			}
		}
	}
	.main-menu {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				margin-bottom: 15px;
				a {
					position: relative;
					display: inline-block;
					vertical-align: top;
					font-size: 18px;
					line-height: 26px;
					color: #232323;
					text-decoration: none;
					&:before {
						content: '';
						position: absolute;
						left: 0;
						bottom: 0;
						width: 0%;
						height: 2px;
						background: #232323;
						@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
					}
					&:hover {
						&:before {
							width: 100%;
						}
					}
				}
				&.active {
					a {
						&:before {
							width: 100%;
						}
					}
				}
			}
		}
	}
	.close {
		position: absolute;
		top: 25px;
		right: 30px;
		width: 24px;
		height: 24px;
		background: url(../images/close.svg) no-repeat;
		background-size: contain;
		cursor: pointer;
	}
}
$base_background: #FFF;
$extra_background: #F9B92B;
$base_text_color: #000000;
$base_white_color: #FFF;
$base_link_color: #F9B92B;
$base_hover_color: #F9B92B;
$base_font_size: 18px;
$base_font_family: 'Helvetica';
$extra_font_family: 'Object Sans';
$base_width: 1200px;
$base_form_placeholder: #000000;
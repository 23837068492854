@media screen and (max-width: 1200px) {
	.fw {
		width: 720px;
	}
	
	/*header*/
	.header {
		position: fixed;
		background: #fff;
	}
	.header .slogan-items {
		display: none;
	}
	.header .main-menu-fw .main-menu ul li a {
		font-size: 16px;
	}
	.header .contacts-cols {
		margin-right: 0;
	}

	/*started*/
	.section.m-started .titles {
		float: none;
	}
	.section.m-started .image {
		margin-top: 13px;
		float: none;
	}

	/*numbers*/
	.numbers-items .numbers-item {
		padding-top: 28px;
	}
	.numbers-items .numbers-item:nth-child(1) {
		width: 50%;
	}
	.numbers-items .numbers-item:nth-child(2) {
		width: 50%;
	}
	.numbers-items .numbers-item:nth-child(3) {
		width: 100%;
	}

	/*services*/
	.services-items {
		float: none;
		width: auto;
	}
	.services-item {
		padding: 30px 10px 10px 30px;
	}
	.fast-services-form {
		padding: 30px;
		width: auto;
		float: none;
	}
	.category-items .category-item {
		padding: 50px 0 0 30px;
		height: 240px;
	}
	.category-items .category-item .desc {
		max-width: 380px;
	}
	.category-items .category-item .desc .title {
		margin-bottom: 30px;
		font-size: 36px;
		line-height: 44px;
	}
	.category-items .category-item .desc .lnks .lnk {
		margin-right: 20px;
	}
	.category-items .category-item .image {
		width: 500px;
	}

	/*equipment*/
	.equipment-items .equipment-col {
		width: 50%;
	}

	/*projects*/
	.projects-item .image {
		width: 290px;
	}
	.projects-item .desc {
		margin-left: 320px;
	}
	.projects-carousel .owl-dots {
		left: 320px;
	}

	/*reviews*/
	.section.m-reviews .m-title {
		margin-bottom: 60px;
	}
	.reviews-item .image {
		display: none;
	}
	.reviews-item .desc {
		margin-left: 0;
	}
	.clients-carousel {
		padding: 40px 80px;
	}
	.clients-carousel .item img {
		max-width: 100%;
	}
	.clients-carousel .owl-prev, .clients-carousel .owl-next {
		left: -60px;
	}
	.clients-carousel .owl-next {
		left: auto;
		right: -60px;
	}

	/*footer*/
	.footer .f-col {
		width: 50%;
	}
	.footer .f-col:nth-child(3) {
		display: none;
	}
	.footer .f-col:nth-child(4) {
		display: none;
	}
	.footer .f-logo {
		margin-top: 50px;
	}

	/*catalog*/
	.h-titles {
		float: none;
		max-width: 100%;
	}
	.h-contacts {
		float: none;
	}
	.catalog-item .image {
		width: 290px;
	}
	.catalog-item .desc {
		width: 330px;
	}
	.catalog-item .name {
		float: none;
		margin-bottom: 10px;
	}
	.catalog-item .articul {
		float: none;
	}
	.catalog-item .buy-bts {
		text-align: left;
	}
	.catalog-item .buy-bts .btn {
		margin-left: 0;
		width: 180px;
	}
	.catalog-item .buy-bts .btn.more-btn {
		width: 100%;
	}
	.catalog-item .buy-bts .btn.one-click-btn {
		font-size: 11px;
	}
	.catalog-item .buy-bts .btn.buy-btn {
		padding-left: 25px;
		float: right;
	}
	.section.m-urgently .h-titles {
		max-width: 100%;
		float: none;
	}
	.section.m-urgently .urgently-images {
		float: none;
		margin-top: 30px;
	}

	/*product*/
	.product-left {
		float: none;
		width: auto;
	}
	.product-left .large-image {
		width: 547px;
	}
	.product-left .small-images {
		width: 547px;
	}
	.product-right {
		float: none;
		width: auto;
		margin-top: 30px;
	}

	/*text*/
	.section.inner-page .cols .col.col-lg {
		float: none;
		width: auto;
	}
	.section.inner-page .cols .col.col-sm {
		margin-top: 40px;
		width: auto;
		float: none;
	}
	.section.m-delivery .text-box {
		width: 300px;
	}
	.section.m-order .m-titles {
		margin-left: 0;
		float: none;
		width: auto;
	}
	.order-form {
		margin-right: 0;
		float: none;
		width: auto;
	}
}

@media screen and (max-width: 740px) {
	.fw {
		width: 300px;
	}
	.wrapper {
		padding-top: 75px;
	}
	.m-title {
		margin: 0 0 30px 0;
		font-size: 28px;
		line-height: 38px;
	}
	.m-title span {
		padding: 0 10px 0 5px;
	}
	.m-title span:before {
		top: 0;
		height: 38px;
	}
	.h-title {
		font-size: 28px;
		line-height: 38px;
	}
	
	/*header*/
	.header {
		padding: 15px 0;
		height: 75px;
	}
	.header .logo {
		width: 180px;
	}
	.header .contacts-cols {
		display: none;
	}
	.header .main-menu-fw {
		display: none;
	}
	.header .menu-btn {
		display: block;
	}

	/*started*/
	.section.m-started {
		padding: 50px 0;
	}
	.section.m-started .titles .title {
		font-size: 28px;
		line-height: 38px;
	}
	.section.m-started .titles .title span {
		margin-left: 0;
		padding: 0 5px;
	}
	.section.m-started .titles .title span:before {
		left: 0;
		top: 0;
		height: 35px;
	}
	.section.m-started .titles .text {
		font-size: 14px;
		line-height: 24px;
	}
	.section.m-started .image {
		margin-top: 10px;
		float: none;
		width: auto;
		height: 150px;
	}

	/*numbers*/
	.section.m-numbers {
		margin-bottom: 50px;
	}
	.numbers-items .numbers-item {
		padding-top: 0;
		margin-bottom: 20px;
		font-size: 14px;
		line-height: 24px;
	}
	.numbers-items .numbers-item:nth-child(1) {
		width: 100%;
	}
	.numbers-items .numbers-item:nth-child(2) {
		width: 100%;
	}
	.numbers-items .numbers-item:nth-child(3) {
		width: 100%;
	}
	.numbers-items .numbers-item .num {
		top: 0;
		font-size: 50px;
		line-height: 50px;
	}
	.benefits-items .benefits-item {
		margin: 0 0 15px 0;
		padding: 0;
		display: block;
		font-size: 14px;
		line-height: 50px;
		height: 51px;
		text-align: center;
	}
	.benefits-items .call-btn {
		margin: 0;
		display: block;
		width: auto;
		font-size: 14px;
		text-align: center;
		line-height: 50px;
		height: 51px;
	}

	/*services*/
	.section.m-services {
		margin: 0 0 50px 0;
	}
	.section.m-services .title {
		font-size: 22px;
	}
	.services-items {
		float: none;
		width: auto;
	}
	.services-cols .services-col {
		margin-bottom: 20px;
		width: 100%;
	}
	.services-item {
		padding: 20px;
	}
	.fast-services-form {
		padding: 20px;
	}
	.category-items .category-item {
		margin-bottom: 30px;
		padding: 20px;
		height: 320px;
	}
	.category-items .category-item .desc .title {
		margin-bottom: 20px;
		font-size: 28px;
		line-height: 38px;
	}
	.category-items .category-item .desc .lnks .lnk {
		font-size: 14px;
	}
	.category-items .category-item .image {
		top: auto;
		bottom: 0;
		width: 100%;
		height: 150px;
		background-size: 480px auto;
		background-position: right bottom;
		border-radius: 0 0 5px 5px;
		-moz-border-radius: 0 0 5px 5px;
		-webkit-border-radius: 0 0 5px 5px;
		-khtml-border-radius: 0 0 5px 5px;
	}

	/*equipment*/
	.section.m-equipment {
		margin: 0 0 20px 0;
	}
	.equipment-items .equipment-col {
		padding-bottom: 30px;
		width: 100%;
	}
	.equipment-item .image {
		height: 180px;
	}
	.equipment-item .desc {
		padding: 10px 0 30px 0;
	}
	.equipment-item .desc .name {
		margin-bottom: 20px;
		font-size: 22px;
		min-height: auto;
	}

	/*projects*/
	.projects-carousel {
		margin-bottom: 50px;
	}
	.projects-item .image {
		float: none;
		width: 100%;
		height: 250px;
		@include border-radius(30px 30px 0 0);
	}
	.projects-item .desc {
		margin-left: 0;
		margin-top: 0;
		padding: 20px;
		padding-bottom: 60px;
	}
	.projects-item .desc .title {
		font-size: 22px;
		line-height: 32px;
	}
	.projects-item .desc .list ul li {
		margin-bottom: 15px;
		font-size: 14px;
	}
	.projects-carousel .owl-nav {
		display: none;
	}
	.projects-carousel .owl-dots {
		left: 20px;
		right: 20px;
		text-align: center;
	}
	.projects-carousel .owl-dots .owl-dot {
		margin-right: 5px;
		margin-left: 5px;
		width: 10px;
		height: 10px;
	}

	/*reviews*/
	.section.m-reviews {
		margin-bottom: 50px;
	}
	.section.m-reviews .m-title {
		margin-bottom: 60px;
	}
	.reviews-item {
		padding-bottom: 30px;
	}
	.reviews-item .image {
		display: none;
	}
	.reviews-item .desc {
		position: relative;
		margin-left: 0;
		padding: 40px 30px;
	}
	.reviews-item .desc .text {
		margin-bottom: 15px;
		font-size: 14px;
		line-height: 24px;
	}
	.reviews-item .desc .text:before, .reviews-item .desc .text:after {
		left: -15px;
		top: -25px;
		width: 20px;
		height: 20px;
	}
	.reviews-item .desc .text:after {
		left: auto;
		top: auto;
		right: 13px;
		bottom: -23px;
	}
	.clients-carousel {
		padding: 30px 80px;
	}
	.clients-carousel .item img {
		max-width: 100%;
	}
	.clients-carousel .owl-prev, .clients-carousel .owl-next {
		margin-top: -20px;
		left: -60px;
		width: 40px;
		height: 40px;
	}
	.clients-carousel .owl-next {
		left: auto;
		right: -60px;
	}

	/*footer*/
	.footer {
		padding: 50px 0;
	}
	.footer .f-col {
		width: 100%;
	}
	.footer .f-col:nth-child(2) {
		display: none;
	}
	.footer .f-title {
		margin: 0 0 30px 0;
	}
	.footer .f-logo {
		margin-top: 30px;
	}
	.nonebox {
		margin-left: -150px;
		padding: 40px 30px 30px 30px;
		width: 300px;
	}
	.nonebox .title {
		font-size: 26px;
		line-height: 28px;
	}
	.c-form .field textarea {
		height: 100px;
	}
	.nonebox .close {
		top: 20px;
		right: 20px;
	}

	/*text-box*/
	.text-box p {
		margin: 15px 0;
		font-size: 14px;
		line-height: 24px;
	}
	.text-box ul li, .text-box ol li {
		margin: 5px 0;
		font-size: 14px;
		line-height: 24px;
	}
	.text-box h1, .text-box h2, .text-box h3, .text-box h4, .text-box h5, .text-box h6 {
		margin: 30px 0 15px 0;
		font-size: 26px;
		line-height: 32px;
	}
	.text-box h4 {
		font-size: 22px;
		line-height: 28px;
	}
	.text-box h5, .text-box h6 {
		font-size: 18px;
		line-height: 24px;
	}
	.text-box blockquote {
		padding: 20px 20px 20px 50px;
		font-size: 16px;
		line-height: 24px;
	}
	.text-box blockquote:after {
		left: 15px;
		top: 15px;
		width: 23px;
	}
	.text-box.table {
		max-width: 300px;
	}
	.text-box table {
		width: 300px;
	}
	.text-box table th, .text-box table td {
		font-size: 14px;
	}
	.white-box {
		padding: 20px;
	}

	/*catalog*/
	.section.section-inner {
		padding: 25px 0;
	}
	.h-titles {
		float: none;
		max-width: 100%;
	}
	.h-contacts {
		padding: 20px;
		float: none;
		width: auto;
		min-height: auto;
	}
	.h-contacts .label {
		font-size: 18px;
	}
	.h-contacts .tel a {
		font-size: 16px;
	}
	.h-contacts .image {
		display: none;
	}
	.catalog-sort {
		margin: 50px 0 25px 0;
	}
	.catalog-sort .label, .catalog-sort a {
		margin-right: 8px;
		font-size: 14px;
	}
	.catalog-item {
		padding: 20px;
	}
	.catalog-item .image {
		margin-bottom: 20px;
		float: none;
		width: auto;
	}
	.catalog-item .desc {
		float: none;
		width: auto;
	}
	.catalog-item .name {
		margin-bottom: 5px;
		font-size: 22px;
	}
	.catalog-item .chars-list {
		margin-top: 0;
		margin-bottom: 20px;
	}
	.chars-list ul li {
		padding: 10px 0;
	}
	.chars-list ul li .val {
		font-size: 14px;
	}
	.chars-list ul li .price {
		bottom: 10px;
		font-size: 14px;
	}
	.catalog-item .articul {
		font-size: 14px;
	}
	.catalog-item .chars-popup {
		max-width: 190px;
	}
	.catalog-item .chars-popup .chars-btn {
		font-size: 16px;
	}
	.catalog-item .buy-bts .btn.one-click-btn {
		width: 100%;
		margin-top: 5px;
	}
	.catalog-item .buy-bts .btn.buy-btn {
		padding-left: 0;
		margin-top: 5px;
		float: none;
		width: 100%;
	}
	.clients-col {
		padding: 15px 0;
		display: block;
	}
	.clients-item {
		padding: 25px 20px;
		height: auto;
		font-size: 16px;
	}
	.clients-item:before {
		top: -15px;
		right: -15px;
		width: 48px;
		height: 48px;
	}
	.clients-item:after {
		display: none;
	}
	.section.m-urgently .tel {
		margin-top: 20px;
		font-size: 16px;
	}
	.section.m-urgently .tel a {
		font-size: 28px;
		line-height: 38px;
	}
	.section.m-urgently .urgently-images {
		float: none;
		margin-top: 30px;
		width: 100%;
	}
	.section.m-urgently .urgently-images img.large {
		float: none;
		width: 100%;
	}
	.section.m-urgently .urgently-images img {
		width: 46%;
	}
	.section.m-urgently .urgently-images a:last-child img {
		margin-right: 8%;
	}

	/*product*/
	.product-left .large-image {
		width: 100%;
	}
	.product-left .small-images {
		width: 100%;
	}
	.product-left .small-images .slide {
		height: 80px;
	}
	.white-box .title {
		font-size: 22px;
	}
	.prices-calc {
		margin: 20px 0;
		font-size: 0;
	}
	.prices-calc .price, .prices-calc .summary {
		max-width: 55px;
	}
	.prices-calc .sep, .prices-calc .sep2 {
		margin: 0 10px;
	}
	.prices-calc .number-value {
		width: 53px;
		text-align: center;
	}
	.prices-itog .itog-value {
		float: none;
		font-size: 14px;
	}
	.prices-itog .itog-value strong {
		font-size: 28px;
	}
	.prices-itog .btn.buy-btn {
		margin-top: 20px;
		width: 100%;
	}
	.product-others .title {
		font-size: 28px;
	}
	.product-others .title span {
		padding-right: 15px;
	}
	.product-others {
		margin-top: 20px;
	}

	/*text*/
	.section.m-delivery .text-box {
		float: none;
		width: auto;
	}
	.section.m-delivery .delivery-list {
		margin-left: -25px;
		float: none;
		width: auto;
	}
	.section.m-delivery .delivery-list ul {
		margin: 0;
	}
	.section.m-delivery .delivery-list ul li {
		font-size: 14px;
		line-height: 24px;
	}
	.others-box .others-list ul li {
		margin-bottom: 15px;
	}
	.others-box .others-list ul li a .lnk {
		width: 160px;
		font-size: 14px;
		line-height: 24px;
	}
	.c-form .group-cols .group-col {
		width: 100%;
	}
}
/* Base */

@font-face {
	font-family: 'Object Sans';
	src: url('../fonts/ObjectSans-Regular.eot');
	src: local('☺'), url('../fonts/ObjectSans-Regular.woff') format('woff'), url('../fonts/ObjectSans-Regular.ttf') format('truetype'), url('../fonts/ObjectSans-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Object Sans';
	src: url('../fonts/ObjectSans-Heavy.eot');
	src: local('☺'), url('../fonts/ObjectSans-Heavy.woff') format('woff'), url('../fonts/ObjectSans-Heavy.ttf') format('truetype'), url('../fonts/ObjectSans-Heavy.svg') format('svg');
	font-weight: 800;
	font-style: normal;
}

* {
	outline: none;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

::-webkit-input-placeholder {color: $base_form_placeholder;}
::-moz-placeholder		{color: $base_form_placeholder;}
:-moz-placeholder		{color: $base_form_placeholder;}
:-ms-input-placeholder	{color: $base_form_placeholder;}

html, body {
	margin: 0;
	padding: 0;
	background: $base_background;
	font-size: $base_font_size;
	color: $base_text_color;
	font-family: $base_font_family, sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
}

a {
	color: $base_link_color;
	text-decoration: none;
	cursor: pointer;
	&:link {
		color: $base_link_color;
	}
	&:active {
		color: $base_link_color;
	}
	&:visited {
		color: $base_link_color;
	}
	&:hover {
		text-decoration: underline; 
	}
	img {
		border: none;
	}
}

input, textarea, button {
	font-family: $base_font_family;
	-webkit-appearance: none;
	box-shadow: none;
}

label {
	cursor: pointer;
}

.clear {
	clear: both;
}

strong {
	font-weight: 700;
}

.centrize {
	display: table !important;
	table-layout: fixed !important;
	height: 100% !important;
	position: relative !important;
	&.full-width {
		width: 100% !important;
	}
}

.vertical-center {
	display: table-cell !important;
	vertical-align: middle !important;
}

.mobile-hide {
	display: block!important;
}

.mobile-show {
	display: none!important;
}

/*Buttons*/

.btn, a.btn {
	padding: 0 30px;
	position: relative;
	display: inline-block;
	vertical-align: top;
	height: 50px;
	font-size: 14px;
	line-height: 50px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #FFFFFF;
	font-weight: bold;
	text-align: center;
	background: #F9B92B;
	border: none;
	outline: none;
	cursor: pointer;
	text-decoration: none;
	@include border-radius(5px);
	@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
	&.buy-btn {
		&:before {
			margin-top: -12px;
			content: '';
			position: absolute;
			left: 13px;
			top: 50%;
			width: 24px;
			height: 24px;
			background: url(../images/submit_ic.svg) no-repeat;
			background-size: contain;
		}
	}
	&:hover {
		@include box-shadow(0 10px 30px rgba(0, 0, 0, 0.15));
	}
}

/*Wrapper*/

.bg {
	position: relative;
	overflow: hidden;
}

.wrapper {
	position: relative;
	padding-top: 170px;
}

.fw {
	position: relative;
	margin: 0 auto;
	width: $base_width;
	&:after {
		content: '';
		clear: both;
		display: block;
	}
}

.disabled {display: none;}

.breadcrumbs {
	position: relative;
	margin: 0 0 35px 0;
	font-size: 0;
	z-index: 2;
	a, span {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		font-size: 14px;
		color: #000000;
		opacity: 0.5;
		text-decoration: none;
	}
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	.home-btn {
		&:before {
			content: '';
			margin-right: 6px;
			position: relative;
			top: -2px;
			display: inline-block;
			vertical-align: middle;
			width: 15px;
			height: 15px;
			background: url(../images/home_ic.svg) no-repeat;
			background-size: contain;
		}
	}
	.sep {
		margin: 0 8px;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 1px;
		height: 10px;
		background: #F9B92B;
	}
}

/*Text*/

.text-box {
	max-width: 961px;
	h1, h2, h3, h4, h5, h6 {
		margin: 30px 0 20px 0;
		font-family: $base_font_family;
		font-size: 36px;
		line-height: 43px;
		color: #000000;
		font-weight: bold;
		&:first-child {
			margin-top: 0;
		}
	}
	> * {
		margin-top: 0!important;
	}
	h4 {
		font-size: 24px;
		line-height: 30px;
	}
	h5, h6 {
		font-size: 20px;
		line-height: 26px;
	}
	p {
		margin: 20px 0;
		font-size: 18px;
		line-height: 26px;
		color: #000000;
		strong {
			font-weight: 700;
		}
		img {
			max-width: 100%;
			&.alignleft {
				float: left;
				max-width: 50%;
				margin-right: 30px;
			}
			&.alignright {
				float: right;
				max-width: 50%;
				margin-left: 30px;
			}
		}
		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
		strong {
			font-weight: 700;
		}
	}
	blockquote {
		position: relative;
		margin: 20px 0;
		padding: 30px 135px;
		max-width: 650px;
		font-size: 20px;
		line-height: 26px;
		color: #000000;
		font-weight: bold;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			border: 1px solid #F9B92B;
			transform: matrix(1, -0.01, -0.06, 1, 0, 0);
			-webkit-transform: matrix(1, -0.01, -0.06, 1, 0, 0);
		}
		&:after {
			content: '';
			position: absolute;
			left: 95px;
			top: 25px;
			width: 30px;
			height: 23px;
			background: url(../images/quote.svg) no-repeat;
			background-size: contain;
		}
	}
	ul, ol {
		margin: 20px 0 20px 15px;
		padding: 0;
		list-style: none;
		li {
			position: relative;
			margin: 10px 0;
			padding: 0 0 0 20px;
			font-size: 18px;
			line-height: 26px;
			color: #000000;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 8px;
				width: 12px;
				height: 12px;
				background: url(../images/point.svg) no-repeat;
				background-size: contain;
			}
		}
	}
	ol {
		counter-reset: myCounter;
		li {
			&:before {
				content:counter(myCounter);
				counter-increment: myCounter;
				top: 0;
				height: 26px;
				font-size: 18px;
				line-height: 26px;
				color: #F9B92B;
				font-weight: 700;
				background: none;
			}
		}
	}
	&.table {
		margin: 40px 0;
		position: relative;
		max-width: 825px;
		overflow: auto;
	}
	table {
		margin: 0;
		padding: 0;
		width: 825px;
		border-collapse: collapse;
		th {
			padding: 0 18px 12px 0;
			height: auto;
			font-size: 18px;
			line-height: 21px;
			color: #000;
			font-weight: bold;
			text-align: left;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		}
		td {
			vertical-align: bottom;
			padding: 28px 18px 18px 0;
			font-weight: 400;
			font-size: 18px;
			line-height: 22px;
			color: #000;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		}
	}
}

/*Preloader*/

.preloader {
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	text-align: center;
	background: $base_white_color;
	z-index: 1000;
	.spinner {
		width: 64px;
		height: 64px;
		display: inline-block;
		position: relative;
		background: url(../images/preloader.gif) no-repeat;
		background-size: 100% 100%;
	}
}

/*Forms*/

.c-form {
	.group {
		margin-bottom: 10px;
	}
	.group-cols {
		margin: 0 -6px;
		font-size: 0;
		.group-col {
			padding: 0 6px 10px 6px;
			display: inline-block;
			vertical-align: top;
			width: 50%;
		}
	}
	.field {
		input, textarea {
			padding: 0 20px;
			width: 100%;
			height: 50px;
			font-family: $base_font_family;
			font-size: 16px;
			line-height: 24px;
			color: #000;
			background: #fff;
			border: none;
			outline: none;
			border: 1px solid rgba(249, 185, 43, 0.5);
			@include border-radius(10px);
		}
		textarea {
			padding-top: 15px;
			padding-bottom: 15px;
			height: 200px;
			resize: none;
		}
		::-webkit-input-placeholder {color: #CECECE;}
		::-moz-placeholder		{color: #CECECE;}
		:-moz-placeholder		{color: #CECECE;}
		:-ms-input-placeholder	{color: #CECECE;}
	}
	.info-text {
		margin-top: 25px;
		font-size: 12px;
		line-height: 18px;
		color: #000;
		text-align: center;
	}
	.submit {
		position: relative;
		&:before {
			margin-top: -12px;
			content: '';
			position: absolute;
			left: 13px;
			top: 50%;
			width: 24px;
			height: 24px;
			background: url(../images/submit_ic.svg) no-repeat;
			background-size: contain;
		}
		.submit-btn {
			display: block;
			width: 100%;
			height: 50px;
			font-size: 14px;
			line-height: 50px;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: #FFFFFF;
			font-weight: bold;
			text-align: center;
			background: #F9B92B;
			border: none;
			outline: none;
			cursor: pointer;
			@include border-radius(5px);
			@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
			&:hover {
				@include box-shadow(0 10px 30px rgba(0, 0, 0, 0.15));
			}
		}
	}
}

/*Popups*/

.overlay, .catalog-filter-overlay {
	display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.8;
	z-index: 99;
}

.nonebox {
	padding: 40px;
	margin-left: -210px;
	position: absolute;
	overflow: hidden;
	left: 50%;
	top: -3000px;
	width: 420px;
	background: #fff;
	z-index: 100;
	.title {
		margin-bottom: 10px;
		font-weight: bold;
		font-size: 30px;
		line-height: 34px;
		color: #000;
	}
	.subtitle {
		margin-bottom: 20px;
		font-size: 14px;
		color: #000;
	}
	.close {
		position: absolute;
		top: 30px;
		right: 30px;
		width: 32px;
		height: 32px;
		background: url(../images/nclose.svg) no-repeat;
		background-size: contain;
		font-size: 0;
		cursor: pointer;
	}
}
/* Footer */

.footer {
	padding: 70px 0 80px 0;
	background: #181818;
	.f-col {
		position: relative;
		float: left;
		width: 25%;
	}
	.f-title {
		margin: 0 0 40px 0;
		font-size: 18px;
		line-height: 25px;
		font-weight: bold;
		text-transform: uppercase;
		color: #fff;
		opacity: 0.4;
	}
	.f-tel, .f-mail {
		margin-bottom: 25px;
		padding-left: 40px;
		position: relative;
		font-size: 16px;
		line-height: 26px;
		color: #fff;
		&:before {
			margin-top: -10px;
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			width: 20px;
			height: 20px;
			background-image: url(../images/ftel.svg);
			background-position: center center;
			background-repeat: no-repeat;
		}
		a {
			display: block;
			color: #fff;
			text-decoration: none;
		}
	}
	.f-mail {
		&:before {
			background-image: url(../images/fmail.svg);
		}
	}
	.f-menu {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				margin-bottom: 20px;
				font-size: 14px;
				line-height: 18px;
				color: #fff;
				a {
					color: #fff;
					text-decoration: none;
					border-bottom: 1px solid transparent;
					@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
					&:hover {
						border-bottom-color: #fff;
					}
				}
				&.active {
					a {
						border-bottom-color: #fff;
					}
				}
			}
		}
	}
	.f-logo {
		margin-top: 120px;
		font-size: 0;
		img {
			max-width: 112px;
		}
	}
}
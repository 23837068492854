/* Section */

.section {
	position: relative;
	z-index: 2;
}

.m-title {
	position: relative;
	margin: 0 0 40px 0;
	font-size: 42px;
	line-height: 48px;
	font-weight: 700;
	color: #000000;
	text-align: center;
	&.left {
		text-align: left;
	}
	span {
		padding: 0 15px 0 5px;
		position: relative;
		display: inline-block;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 2px;
			width: 100%;
			height: 44px;
			background: #FBBA00;
			-webkit-transform: rotate(-1.37deg);
			transform: rotate(-1.37deg);
		}
		em {
			position: relative;
			font-style: normal;
			color: #fff;
		}
	}
}

/* Section Started */

.section.m-started {
	padding-top: 70px;
	padding-bottom: 70px;
	.titles {
		position: relative;
		float: left;
		max-width: 500px;
		.title {
			position: relative;
			margin: 0 0 18px 0;
			font-family: $extra_font_family;
			font-size: 64px;
			line-height: 66px;
			font-weight: 800;
			color: #000000;
			span {
				margin-left: -10px;
				padding: 0 10px;
				position: relative;
				display: inline-block;
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: -3px;
					width: 100%;
					height: 66px;
					background: #FBBA00;
					-webkit-transform: matrix(1, -0.01, -0.05, 1, 0, 0);
					transform: matrix(1, -0.01, -0.05, 1, 0, 0);
				}
				em {
					position: relative;
					font-style: normal;
					color: #fff;
				}
			}
		}
		.text {
			font-size: 16px;
			line-height: 27px;
			color: #000000;
			opacity: 0.5;
		}
	}
	.image {
		margin-top: -35px;
		position: relative;
		float: right;
		width: 673px;
		height: 332px;
		background: url(../images/started.png) no-repeat;
		background-size: contain;
	}
}

/* Section Numbers */

.section.m-numbers {
	margin-bottom: 70px;
}

.numbers-items {
	text-align: center;
	font-size: 0;
	.numbers-item {
		padding-top: 48px;
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: 33.333%;
		font-size: 18px;
		line-height: 21px;
		color: #000000;
		text-align: left;
		&:nth-child(1) {
			width: 30%;
		}
		&:nth-child(2) {
			width: 30%;
		}
		&:nth-child(3) {
			width: 40%;
		}
		&:after {
			content: '';
			position: relative;
			clear: both;
		}
		.num {
			position: relative;
			top: -48px;
			margin-right: 10px;
			float: left;
			font-size: 96px;
			line-height: 110px;
			color: #F9B92B;
			font-weight: bold;
		}
	}
}

.benefits-items {
	font-size: 0;
	.benefits-item {
		margin: 0 25px 25px 0;
		padding: 0 50px;
		display: inline-block;
		vertical-align: top;
		font-size: 16px;
		color: #000;
		line-height: 70px;
		height: 71px;
		background: #F6F6F6;
		@include border-radius(10px);
	}
	.call-btn {
		margin: 0 25px 25px 0;
		display: inline-block;
		vertical-align: top;
		width: 300px;
		font-size: 16px;
		color: #fff;
		text-align: center;
		text-decoration: none;
		line-height: 70px;
		height: 71px;
		background: #F9B92B;
		@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
		@include border-radius(10px);
		span {
			line-height: 24px;
			border-bottom: 1px solid #fff;
			@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
		}
		&:hover {
			@include box-shadow(0 10px 30px rgba(0, 0, 0, 0.15));
			span {
				border-bottom-color: transparent;
			}
		}
	}
}

/* Section Services */

.section.m-services {
	margin: 0 0 100px 0;
	.title {
		margin-bottom: 30px;
		font-size: 24px;
		line-height: 24px;
		color: #212127;
		font-weight: bold;
		a {
			color: #212127;
			text-decoration: none;
		}
	}
}

.services-items {
	position: relative;
	width: 802px;
	float: left;
}

.services-cols {
	margin: 0 -17px;
	font-size: 0;
	.services-col {
		margin-bottom: 34px;
		padding: 0 17px;
		display: inline-block;
		vertical-align: top;
		width: 50%;
	}
}

.services-item {
	padding: 30px 30px 10px 30px;
	position: relative;
	background: #F6F6F6;
	@include border-radius(5px);
	.list {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				margin-bottom: 20px;
				a {
					padding-bottom: 2px;
					display: inline;
					font-family: $extra_font_family;
					font-size: 14px;
					line-height: 16px;
					color: #000000;
					text-decoration: none;
					border-bottom: 1px solid rgba(0, 0, 0, 0.2);
					@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
					&:hover {
						border-bottom-color: rgba(0, 0, 0, 1);
					}
				}
			}
		}
	}
}

.fast-services-form {
	padding: 30px;
	position: relative;
	width: 364px;
	float: right;
	border: 1px solid #F6F6F6;
	@include border-radius(5px);
	.group {
		margin-bottom: 15px;
	}
	.field {
		input, textarea {
			padding: 0 20px;
			width: 100%;
			height: 50px;
			font-family: $base_font_family;
			font-size: 16px;
			line-height: 24px;
			color: #000;
			background: #F6F6F6;
			border: none;
			outline: none;
			@include border-radius(5px);
		}
		textarea {
			padding-top: 15px;
			padding-bottom: 15px;
			height: 200px;
			resize: none;
		}
		::-webkit-input-placeholder {color: #CECECE;}
		::-moz-placeholder		{color: #CECECE;}
		:-moz-placeholder		{color: #CECECE;}
		:-ms-input-placeholder	{color: #CECECE;}
	}
	.info-text {
		margin-bottom: 25px;
		font-size: 14px;
		line-height: 22px;
		color: #CECECE;
	}
	.submit {
		position: relative;
		&:before {
			margin-top: -12px;
			content: '';
			position: absolute;
			left: 13px;
			top: 50%;
			width: 24px;
			height: 24px;
			background: url(../images/submit_ic.svg) no-repeat;
			background-size: contain;
		}
		.submit-btn {
			display: block;
			width: 100%;
			height: 50px;
			font-size: 14px;
			line-height: 50px;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: #FFFFFF;
			font-weight: bold;
			text-align: center;
			background: #F9B92B;
			border: none;
			outline: none;
			cursor: pointer;
			@include border-radius(5px);
			@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
			&:hover {
				@include box-shadow(0 10px 30px rgba(0, 0, 0, 0.15));
			}
		}
	}
}

.category-items {
	padding-top: 30px;
	.category-item {
		position: relative;
		margin-bottom: 50px;
		padding: 70px 0 0 60px;
		height: 350px;
		background: #F6F6F6;
		@include border-radius(5px);
		&.item-1 {
			.desc {
				.title {
					strong {
						color: #F9B92B;
					}
				}
			}
		}
		&.item-2 {
			.desc {
				.title {
					strong {
						color: #786E57;
					}
				}
			}
		}
		&.item-3 {
			.desc {
				.title {
					strong {
						color: #7A4B23;
					}
				}
			}
		}
		.desc {
			position: relative;
			max-width: 520px;
			.title {
				margin-bottom: 40px;
				font-weight: bold;
				font-size: 64px;
				line-height: 74px;
				color: #000;
				a {
					color: #000;
					text-decoration: none;
				}
			}
			.lnks {
				.lnk {
					margin-right: 40px;
					display: inline-block;
					vertical-align: top;
					font-size: 16px;
					line-height: 26px;
					letter-spacing: 0.1em;
					text-transform: uppercase;
					color: #000;
					text-decoration: none;
					border-bottom: 1px solid rgba(0, 0, 0, 0);
					@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
					&:first-child {
						border-bottom: 1px solid rgba(0, 0, 0, 1);
						&:hover {
							border-bottom-color: #F9B92B;
						}
					}
					&:hover {
						color: #F9B92B;
					}
				}
			}
		}
		.image {
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			width: 670px;
			height: 100%;
			background-repeat: no-repeat;
			background-position: right center;
			background-size: auto 100%;
			@include border-radius(0 5px 5px 0);
		}
	}
}

/* Section Equipment */

.section.m-equipment {
	margin: 0 0 60px 0;
}

.equipment-items {
	margin: 0 -15px;
	font-size: 0;
	.equipment-col {
		padding: 0 15px 48px 15px;
		display: inline-block;
		vertical-align: top;
		width: 33.333%;
	}
}

.equipment-item {
	position: relative;
	text-align: center;
	background: #F6F6F6;
	@include border-radius(10px);
	.image {
		position: relative;
		overflow: hidden;
		height: 232px;
		font-size: 0;
		img {
			width: 100%;
			height: auto;
			@include border-radius(10px 10px 0 0);
		}
	}
	.desc {
		padding: 15px 0 50px 0;
		.name {
			margin: 0 auto;
			width: 250px;
			min-height: 112px;
			font-weight: bold;
			font-size: 24px;
			a {
				color: #000;
				text-decoration: none;
			}
		}
		.lnks {
			.lnk {
				margin: 0 20px;
				display: inline-block;
				vertical-align: top;
				font-size: 16px;
				line-height: 26px;
				letter-spacing: 0.1em;
				text-transform: uppercase;
				color: #000;
				text-decoration: none;
				border-bottom: 1px solid rgba(0, 0, 0, 0);
				@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
				&:first-child {
					border-bottom: 1px solid rgba(0, 0, 0, 1);
					&:hover {
						border-bottom-color: #F9B92B;
					}
				}
				&:hover {
					color: #F9B92B;
				}
			}
		}
	}
}

/* Section Projects */

.projects-carousel {
	margin-bottom: 110px;
	position: relative;
	background: #FFFFFF;
	@include border-radius(30px);
	@include box-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
	.owl-carousel .owl-stage-outer {
		padding-bottom: 0;
	}
	.owl-dots {
		position: absolute;
		left: 610px;
		bottom: 30px;
		font-size: 0;
		.owl-dot {
			margin-right: 22px;
			display: inline-block;
			vertical-align: top;
			width: 15px;
			height: 15px;
			background: rgba(23, 17, 6, 0.3);
			cursor: pointer;
			@include border-radius(15px);
			&.active {
				background: rgba(23, 17, 6, 1);
			}
		}
	}
	.owl-prev, .owl-next {
		position: absolute;
		right: 30px;
		bottom: 30px;
		width: 58px;
		height: 56px;
		background: url(../images/nav.svg) no-repeat;
		background-size: contain;
		cursor: pointer;
		font-size: 0;
	}
	.owl-prev {
		right: 100px;
		@include transform(scaleX(-1));
	}
}

.projects-item {
	position: relative;
	padding: 0;
	.image {
		position: relative;
		float: left;
		width: 548px;
		height: 523px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		@include border-radius(30px 0 0 30px);
	}
	.desc {
		margin-left: 610px;
		padding-top: 30px;
		padding-right: 30px;
		.title {
			margin-bottom: 15px;
			font-weight: bold;
			font-size: 36px;
			line-height: 41px;
			color: #000;
		}
		.adr {
			margin-bottom: 20px;
			padding-left: 20px;
			position: relative;
			font-size: 14px;
			line-height: 21px;
			color: rgba(0, 0, 0, 0.6);
			&:before {
				margin-top: -5px;
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				width: 10px;
				height: 10px;
				background: url(../images/loc.svg) no-repeat;
				background-size: contain;
			}
		}
		.list {
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				li {
					position: relative;
					padding-left: 20px;
					margin-bottom: 20px;
					font-size: 16px;
					color: #000;
					&:before {
						margin-top: -5px;
						content: '';
						position: absolute;
						left: 0;
						top: 50%;
						width: 10px;
						height: 10px;
						background: #F9B92B;
						@include border-radius(10px);
					}
				}
			}
		}
	}
}

/* Section Reviews */

.section.m-reviews {
	margin-bottom: 120px;
	.m-title {
		margin-bottom: 120px;
	}
}

.reviews-item {
	padding-bottom: 45px;
	position: relative;
	.image {
		position: absolute;
		left: 30px;
		bottom: 0;
		width: 411px;
		height: 450px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
	}
	.desc {
		position: relative;
		margin-left: 540px;
		padding: 60px 65px;
		background: #FFFFFF;
		@include box-shadow(0px 4px 20px rgba(0, 0, 0, 0.05));
		@include border-radius(10px);
		.text {
			position: relative;
			margin-bottom: 15px;
			font-size: 18px;
			line-height: 22px;
			color: rgba(0, 0, 0, 0.5);
			&:before, &:after {
				content: '';
				position: absolute;
				left: -33px;
				top: -30px;
				width: 27px;
				height: 23px;
				background: url(../images/quote.svg) no-repeat;
				background-size: contain;
			}
			&:after {
				left: auto;
				top: auto;
				right: 13px;
				bottom: -23px;
				@include transform(scale(-1));
			}
		}
		.name {
			font-size: 14px;
			line-height: 16px;
			color: #F9B92B;
		}
		.video-lnk {
			position: absolute;
			top: -40px;
			right: 15px;
			font-size: 14px;
			line-height: 24px;
			color: #000;
			text-decoration: none;
			border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
			@include transition(all 0.3s ease 0s);
			&:hover {
				color: $base_hover_color;
				border-bottom-color: $base_hover_color;
			}
		}
	}
}

.clients-carousel {
	padding: 75px 125px;
	position: relative;
	background: #FFFFFF;
	@include box-shadow(0px 3px 8px rgba(0, 0, 0, 0.15));
	@include border-radius(30px);
	.item {
		font-size: 0;
		text-align: center;
		img {
			max-width: 216px;
		}
	}
	.owl-prev, .owl-next {
		margin-top: -25px;
		position: absolute;
		left: -100px;
		top: 50%;
		width: 50px;
		height: 50px;
		font-size: 0;
		cursor: pointer;
		background: url(../images/nav2.svg) no-repeat;
		background-size: contain;
	}
	.owl-next {
		left: auto;
		right: -100px;
		@include transform(scaleX(-1));
	}
}
/* Section Inner */

.section.section-inner {
	padding: 50px 0;
}

.h-title {
	position: relative;
	margin: 0 0 25px 0;
	font-weight: bold;
	font-size: 48px;
	line-height: 55px;
	color: #000000;
}

.white-box {
	margin: 0 0 30px 0;
	padding: 30px;
	position: relative;
	background: #FFFFFF;
	@include box-shadow(0px 4px 65px rgba(0, 0, 0, 0.1));
	@include border-radius(10px);
	.title {
		margin: 0;
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		color: #000;
	}
}

/* Section Catalog */

.h-titles {
	position: relative;
	float: left;
	max-width: 610px;
}

.h-contacts {
	padding: 25px 220px 0 30px;
	position: relative;
	float: right;
	width: 494px;
	min-height: 141px;
	background: #fff;
	@include box-shadow(0px 4px 65px rgba(0, 0, 0, 0.1));
	@include border-radius(10px);
	.label {
		margin-bottom: 15px;
		font-weight: bold;
		font-size: 24px;
		line-height: 24px;
		color: #000000;
	}
	.tel {
		a {
			display: block;
			margin-bottom: 5px;
			font-size: 18px;
			color: #000000;
		}
	}
	.image {
		position: absolute;
		top: 0;
		right: 0;
		width: 220px;
		height: 100%;
		background-repeat: no-repeat;
		background-position: right center;
		background-size: auto 100%;
	}
}

.catalog-sort {
	margin: 95px 0 25px 0;
	.label, a {
		margin-right: 20px;
		display: inline-block;
		vertical-align: top;
		font-size: 18px;
		line-height: 22px;
		color: #000;
		font-weight: bold;
	}
	a {
		color: rgba(0, 0, 0, 0.5);
		font-size: 14px;
		font-weight: 400;
		text-decoration: none;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
		&.active {
			color: rgba(0, 0, 0, 1);
		}
	}
}

.catalog-item {
	margin-bottom: 30px;
	padding: 35px;
	background: #FFFFFF;
	@include box-shadow(0px 4px 65px rgba(0, 0, 0, 0.1));
	@include border-radius(10px);
	.image {
		float: left;
		width: 370px;
		font-size: 0;
		img {
			max-width: 100%;
			@include border-radius(10px);
		}
	}
	.desc {
		float: right;
		width: 720px;
	}
	.name {
		float: left;
		max-width: 480px;
		font-weight: bold;
		font-size: 24px;
		line-height: 24px;
		a {
			color: #000000;
			text-decoration: none;
		}
	}
	.articul {
		float: right;
		font-size: 18px;
		line-height: 24px;
		color: #000000;
	}
	.chars-popup {
		position: relative;
		max-width: 210px;
		.chars-btn {
			padding-right: 25px;
			display: block;
			font-size: 18px;
			line-height: 24px;
			color: #000;
			text-decoration: none;
			&:after {
				margin-top: -5px;
				content: '';
				position: absolute;
				top: 50%;
				right: 0;
				width: 15px;
				height: 10px;
				background: url(../images/arr.svg) no-repeat;
				background-size: contain;
			}
		}
	}
	.buy-bts {
		text-align: right;
		.btn {
			margin-top: 20px;
			margin-left: 40px;
			padding: 0;
			width: 250px;
		}
		.btn.more-btn {
			background: #CECECE;
			&:before {
				background: url(../images/more_ic.svg) no-repeat;
				background-position: center center;
				background-size: contain;
			}
		}
		.btn.one-click-btn {
			padding: 0;
			width: auto;
			color: #F9B92B;
			background: none;
			@include box-shadow(none!important);
			&:before {
				display: none;
			}
			span {
				padding-bottom: 5px;
				border-bottom: 1px dashed rgba(249, 185, 43, 0.4);
			}
			&:hover {
				span {
					border-bottom-color: transparent;
				}
			}
		}
	}
}

.chars-list {
	margin-top: 15px;
	margin-bottom: 20px;
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			position: relative;
			padding: 15px 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.3);
			.val {
				position: relative;
				display: block;
				font-size: 18px;
				color: #000;
				em {
					margin-top: 5px;
					display: block;
					font-style: normal;
					font-size: 12px;
					line-height: 100%;
					color: #F9B92B;
				}
			}
			.price {
				position: absolute;
				right: 0;
				bottom: 15px;
				font-size: 18px;
				color: #000;
				font-weight: bold;
			}
		}
	}
}

.clients-items {
	margin-left: -55px;
	margin-top: -15px;
	font-size: 0;
}

.clients-col {
	padding: 45px 0;
	margin-left: 55px;
	position: relative;
	display: inline-block;
	vertical-align: top;
}

.clients-item {
	padding: 0 60px;
	position: relative;
	display: flex;
	align-items: center;
	height: 123px;
	background: #FFFFFF;
	font-size: 18px;
	line-height: 24px;
	color: #000000;
	@include box-shadow(0px 4px 65px rgba(0, 0, 0, 0.1));
	@include border-radius(10px);
	&:before {
		content: '';
		position: absolute;
		top: -34px;
		right: -34px;
		width: 68px;
		height: 68px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		z-index: 2;
	}
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		right: -55px;
		width: 55px;
		height: 30px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 100% auto;
		z-index: 3;
	}
	&.item-1 {
		&:before {
			background-image: url(../images/client_ic1.svg);
		}
		&:after {
			background-image: url(../images/c_line1.svg);
		}
	}
	&.item-2 {
		&:before {
			background-image: url(../images/client_ic2.svg);
		}
		&:after {
			background-image: url(../images/c_line2.svg);
		}
	}
	&.item-3 {
		&:before {
			background-image: url(../images/client_ic3.svg);
		}
	}
	&.item-4 {
		&:before {
			background-image: url(../images/client_ic4.svg);
		}
		&:after {
			background-image: url(../images/c_line2.svg);
		}
	}
	&.item-5 {
		&:before {
			background-image: url(../images/client_ic5.svg);
		}
	}
}

.section.m-urgently {
	.h-titles {
		max-width: 480px;
	}
	.tel {
		margin-top: 60px;
		font-family: $extra_font_family;
		font-size: 24px;
		color: #000000;
		a {
			display: block;
			font-weight: 800;
			font-size: 48px;
			line-height: 48px;
			color: #000000;
		}
	}
	.urgently-images {
		float: right;
		width: 678px;
		img {
			margin-bottom: 20px;
			float: right;
			width: 247px;
			@include box-shadow(0px 3px 8px rgba(0, 0, 0, 0.15));
			@include border-radius(10px);
			&.large {
				float: left;
				width: 411px;
			}
		}
	}
}

/* Section Product */

.product-top {
	position: relative;
	.h-title {
		max-width: 547px;
	}
	.owl-carousel .owl-stage-outer {
		padding-bottom: 0;
	}
}

.product-left {
	float: left;
	width: 547px;
	.large-image {
		.slide {
			margin-bottom: 25px;
			position: relative;
			width: 100%;
			height: 264px;
			display: block;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			@include border-radius(10px);
		}
	}
	.small-images {
		margin-bottom: 30px;
		.slide {
			position: relative;
			width: 100%;
			height: 137px;
			display: block;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			cursor: pointer;
			@include transition(all 0.3s ease 0s);
			@include border-radius(10px);
			&.active {
				opacity: 0.5;
			}
		}
	}
	.h-contacts {
		margin-top: 30px;
		float: none;
	}
}

.product-description {
	.title {
		margin-bottom: 15px;
		font-weight: bold;
		font-size: 24px;
		line-height: 28px;
		color: #000;
	}
}

.product-right {
	float: right;
	width: 590px;
	.cols {
		position: relative;
		font-size: 0;
		.col {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: 35%;
			font-size: 14px;
			&:first-child {
				padding-right: 55px;
				width: 65%;
			}
		}
	}
	.chars-list {
		margin: 0;
		ul {
			li {
				padding: 10px 0;
				.price {
					bottom: 10px;
				}
			}
		}
	}
}

.prices-calc {
	margin: 30px 0 20px 0;
	font-size: 0;
	.price, .summary {
		display: inline-block;
		vertical-align: middle;
		font-weight: bold;
		font-size: 20px;
		line-height: 23px;
		color: #000;
		opacity: 0.4;
		span {
			display: block;
			font-size: 9px;
			line-height: 10px;
			color: #000;
		}
	}
	.summary {
		opacity: 1;
		span {
			opacity: 0.5;
		}
	}
	.sep {
		margin: 0 20px;
		display: inline-block;
		vertical-align: middle;
		width: 10px;
		height: 10px;
		background: url(../images/sep.svg) no-repeat center center;
	}
	.sep2 {
		margin: 0 15px;
		display: inline-block;
		vertical-align: middle;
		font-family: $extra_font_family;
		font-size: 24px;
		line-height: 25px;
		color: #000;
		opacity: 0.4;
	}
	.number-value {
		display: inline-block;
		vertical-align: middle;
		width: 280px;
		input {
			padding: 0 20px;
			width: 100%;
			height: 53px;
			font-family: $base_font_family;
			font-size: 14px;
			color: #000;
			background: #fff;
			border: 1px solid rgba(249, 185, 43, 0.5);
			@include border-radius(44px);
		}
		input[type=number]::-webkit-outer-spin-button,
		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		input[type=number] {
			-moz-appearance:textfield;
		}
	}
}

.prices-info {
	margin-bottom: 30px;
	padding-bottom: 15px;
	font-size: 14px;
	line-height: 24px;
	color: rgba(0,0,0,0.7);
	border-bottom: 1px solid rgba(0,0,0,0.3);
}

.prices-del {
	margin: 0 0 30px 0;
	.del-value {
		margin-top: 20px;
		input {
			padding: 0 20px;
			width: 100%;
			height: 53px;
			font-family: $base_font_family;
			font-size: 14px;
			color: #000;
			background: #fff;
			border: 1px solid rgba(249, 185, 43, 0.5);
			@include border-radius(44px);
		}
	}
}

.prices-itog {
	.itog-value {
		float: left;
		font-size: 18px;
		line-height: 21px;
		color: rgba(0,0,0,0.5);
		strong {
			display: block;
			font-size: 36px;
			line-height: 41px;
			color: #000;
			font-weight: bold;
		}
	}
	.btn.buy-btn {
		margin-top: 10px;
		float: right;
		padding: 0;
		width: 220px;
	}
}

.product-others {
	margin-top: 60px;
	.title {
		margin: 0 0 30px 0;
		position: relative;
		font-weight: bold;
		font-size: 36px;
		line-height: 41px;
		color: #000000;
		&:before {
			margin-top: -1px;
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			width: 100%;
			height: 3px;
			background: #F9B92B;
		}
		span {
			position: relative;
			padding-right: 30px;
			position: relative;
			background: #fff;
		}
	}
}

/* Section Inner Page */

.section.inner-page {
	.cols {
		.col {
			float: left;
			&.col-sm {
				width: 320px;
			}
			&.col-lg {
				float: right;
				width: 825px;
			}
		}
	}
}

.others-box {
	margin-bottom: 38px;
	&:last-child {
		margin-bottom: 0;
	}
	.title {
		margin-bottom: 20px;
		font-size: 24px;
		line-height: 24px;
		color: #000;
		font-weight: bold;
	}
	.others-list {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				position: relative;
				margin-bottom: 22px;
				&:after {
					content: '';
					position: relative;
					display: block;
					clear: both;
				}
				&:last-child {
					margin-bottom: 0;
				}
				a {
					position: relative;
					text-decoration: none;
					.image {
						margin-right: 13px;
						display: inline-block;
						vertical-align: middle;
						width: 67px;
						height: 67px;
						background-position: center center;
						background-size: cover;
						background-repeat: no-repeat;
						@include border-radius(100%);
					}
					.lnk {
						display: inline-block;
						vertical-align: middle;
						width: 170px;
						font-size: 18px;
						line-height: 24px;
						color: #000;
						text-decoration: none;
						span {
							display: inline;
							border-bottom: 1px solid rgba(0,0,0,0.2);
							@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
						}
					}
					&:hover {
						.lnk {
							color: #F5A900;
							span {
								border-bottom-color: #F5A900;
							}
						}
					}
				}
			}
		}
	}
	.services-list {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				margin-bottom: 12px;
				a {
					display: inline;
					font-size: 14px;
					line-height: 20px;
					color: #000;
					text-decoration: none;
					border-bottom: 1px solid rgba(0,0,0,0.2);
					@include transition(all 0.7s cubic-bezier(0.165, 0.85, 0.45, 1));
					&:hover {
						color: #F5A900;
						border-bottom-color: #F5A900;
					}
				}
			}
		}
	}
}

.section.m-delivery {
	margin-bottom: 30px;
	position: relative;
	.text-box {
		float: left;
		width: 600px;
	}
	.delivery-list {
		float: right;
		width: 370px;
		ul {
			margin: 0 0 0 -50px;
			padding: 0;
			list-style: none;
			font-size: 0;
			li {
				margin: 0 0 5px 0;
				padding-left: 50px;
				position: relative;
				display: inline-block;
				vertical-align: top;
				width: 33.333%;
				font-size: 18px;
				line-height: 24px;
				color: #000;
				&:before {
					margin-top: -5px;
					content: '';
					position: absolute;
					left: 25px;
					top: 50%;
					width: 10px;
					height: 10px;
					background: #F9B92B;
					@include border-radius(10px);
				}
			}
		}
	}
}

.delivery-map {
	margin: 25px 0 0 0;
	font-size: 0;
	@include box-shadow(0px 4px 65px rgba(0, 0, 0, 0.1));
	@include border-radius(10px);
	img {
		max-width: 100%;
	}
	.map {
		position: relative;
		width: 100%;
		height: 463px;
	}
}

.section.m-order {
	padding-bottom: 30px;
	.m-titles {
		margin-left: 35px;
		float: left;
		width: 410px;
	}
	.white-box {
		background: rgba(255,255,255,0.5);
	}
}

.order-form {
	margin-right: 145px;
	float: right;
	width: 460px;
}